@import "~react-tabs/style/react-tabs.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~react-toastify/dist/ReactToastify.css";

@tailwind components;
@tailwind base;
@tailwind utilities;

// @font-face {
//   font-family: 'HY-Koma';
//   src: url('../../public/fonts/HY-Koma.eot');
//   src: url('../../public/fonts/HY-Koma.eot?#iefix') format('embedded-opentype'),
//   url('../../public/fonts/HY-Koma.woff2') format('woff2'),
//   url('../../public/fonts/HY-Koma.woff') format('woff'),
//   url('../../public/fonts/HY-Koma.ttf') format('truetype'),
//   url('../../public/fonts/HY-Koma.svg#HY-Koma') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "Gilroy";
  src: url("../../public/fonts/GILROY/SVN-Gilroy\ Bold.otf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("../../public/fonts/GILROY/SVN-Gilroy\ Bold\ Italic.otf");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

body {
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-transform: none;
  word-wrap: normal;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: normal;
  @apply bg-background;
  @apply text-17 md:text-14;
  @apply text-white;
  @apply font-primary;
}

strong {
  display: block;
  font-weight: 700;
}

input,
textarea {
  &:focus {
    outline: none;
  }
}

.text-shadow {
  text-shadow: 2px 0 rgba(229, 228, 234, 0.05),
  -2px 0 rgba(229, 228, 234, 0.05),
  0 2px rgba(229, 228, 234, 0.05),
  0 -2px rgba(229, 228, 234, 0.05),
  1px 1px rgba(229, 228, 234, 0.05),
  -1px -1px rgba(229, 228, 234, 0.05),
  1px -1px rgba(229, 228, 234, 0.05),
  -1px 1px rgba(229, 228, 234, 0.05);
  @apply text-[148px];
  @apply leading-[200px];
  @apply text-background;
  @apply font-secondary;
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none; // Chrome, Safari, Opera
  }
}

.box-gradient {
  position: relative;
  box-sizing: border-box;

  background-clip: padding-box; /* !importanté */
  border-radius: 32px 12px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    border-radius: inherit; /* !importanté */
    @apply bg-background;
  }

  img {
    border-radius: 44px 20px;
    position: relative;
    @apply md:rounded-button;
  }
}

.react-player__preview {
  background-color: black !important;
}

.react-player__shadow {
  background-image: url("../../public/icons/play.svg") !important;
  background-size: contain !important;

  .react-player__play-icon {
    display: none;
  }
}

.exam-card {
  &:nth-child(4n-2) {
    padding-right: 10%;
    @apply md:pr-0;
  }

  &:nth-child(4n-1) {
    padding-left: 10%;
    @apply md:pl-0;
  }
}

.slick-dots-customize {
  &.slick-dots-customize--introduce {
    .slick-arrow {
      top: 50%;
    }
  }

  @media (max-width: 767px) {
    .slick-arrow,
    .slick-dots {
      display: none !important;
    }
  }
}

.slick-customize--van-xuan-stars {
  //.slick-track {
  //  display: flex;
  //  align-items: center;
  //}

  @media (max-width: 767px) {
    .slick-track {
      align-items: start;
    }

    .slick-slider {
      padding-top: 40px;
    }

    .slick-arrow {
      &.slick-prev {
        top: 0;
        left: 16px;
      }
      &.slick-next {
        top: 0;
        right: 16px;
      }
    }
  }
}

.slick-dots.slick-dots--introduce {
  flex-wrap: nowrap;

  li {
    @apply w-auto opacity-30;

    &.slick-active {
      @apply opacity-100;
    }
  }
}

.slick-thumb {
  display: flex !important;
  @apply relative items-center justify-center flex-wrap gap-y-6;

  li {
    @apply w-full h-full max-w-[154px] m-0;

    .slider-item {
      @apply relative before:bg-black/70 before:absolute before:inset-0 before:z-10 before:rounded-button before:transition-all;
    }

    &.slick-active {
      .slider-item:before {
        content: none;
      }
    }
  }

  .react-player__preview {
    aspect-ratio: 154/80;
  }

  .react-player__shadow {
    width: 48px !important;
    height: 48px !important;
  }
}

.react-thumbnail-generator {
  position: fixed;
  width: 0;
  height: 0;
}

.react-tooltip.react-tooltip__show {
  @apply max-w-[480px] p-6 rounded-[16px] font-primary opacity-[1] translate-y-[-100px] z-[99] text-16;

  .react-tooltip-arrow {
    display: none;
  }
}

.vi__wrapper {
  @apply w-full;
}

@layer components {
  .text-yellowGradient {
    @apply bg-yellowGradient bg-clip-text text-transparent;
  }

  .text-blueGradient {
    @apply text-yellowGradient bg-blueGradient;
  }

  .text-blueGradientGradient {
    @apply text-yellowGradient bg-pinkGradient;
  }

  .border-blueGradient {
    @apply before:absolute before:bottom-0 before:left-0 before:h-[1px] before:w-full before:bg-blueGradient;
  }

  .border-v-blueGradient {
    @apply before:absolute before:top-0 before:right-0 before:h-full before:w-[1px] before:rotate-180 before:bg-blueGradientSecondary;
  }

  .border-yellowGradient {
    @apply border-blueGradient before:bg-yellowGradient;
  }

  .vr-container {
    @apply h-[60px] w-full;
  }

  .vr-character {
    line-height: 56px !important;
    @apply w-[102px] rounded-lg bg-gray3 font-primary text-24 md:w-full;
  }
}

.Toastify__toast-body {
  @apply font-primary text-18;
}


.language-popover {
  z-index: 99;
  @media (max-width: 767px) {
    z-index: 99;
  }
}

.react-tiny-popover-container:not(.language-popover) {
  @media (max-width: 767px) {
    transform: none !important;
    z-index: 99;
  }
}

// Start - Loading
.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}

@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

// End - Loading

@keyframes bounce {
  20%, 50%, 80%, 100% {
    transform: scale(0.98);
  }
}

.tags {
  --offset: 100vw;
  --move-initial: calc(-0% + var(--offset));
  --move-middle: calc(-75% + var(--offset));
  --move-final: calc(-150% + var(--offset));
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 40s linear infinite;
  animation-play-state: running;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  50% {
    transform: translate3d(var(--move-middle), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

button:active:not(:disabled):not(.slick-next):not(.slick-prev),
a:active {
  animation: bounce 0.5s ease;
}

.react-select__menu {
  z-index: 99 !important;
}
